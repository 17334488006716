export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

export const PollTypes = Object.freeze({
    QA: 'App\\Models\\PollResponses\\Question',
    WORD_CLOUD: 'App\\Models\\PollResponses\\WordCloud',
    MULTIPLE_CHOICE: 'App\\Models\\PollResponses\\MultipleChoice',
    RATING: 'App\\Models\\PollResponses\\Rating',
    OPEN_TEXT: 'App\\Models\\PollResponses\\OpenText',
    RANKING: 'App\\Models\\PollResponses\\Ranking',
});

export function deepen(flatObject) {
    const result = {};

    for (const key in flatObject) {
        const parts = key.split('.');
        let current = result;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const isLastPart = i === parts.length - 1;

            if (isLastPart) {
                // Directly set the value if this is the last part
                current[part] = flatObject[key];
            } else {
                // Prepare the structure for deeper nesting
                if (!current[part] || typeof current[part] !== 'object') {
                    // Assume an object for the next part, unless the next part is clearly an array index
                    current[part] = isNaN(Number(parts[i + 1])) ? {} : [];
                }
                current = current[part];
            }
        }
    }

    return result;
}

export function can(ability, user) {
    return !!user?.allPermissions?.some(permission => permission.toLocaleLowerCase() === ability.toLowerCase());
}

/**
 * The first ranked answer get as many points as number of answers
 *
 * @param {Array<string>} answers
 * @returns {Array<string>}
 */
export function rankAnswers(answers) {
    return answers.map((answer, index) => Array.from({ length: answers.length - index }).fill(answer)).flat();
}

/**
 * Serialize blob to base64 string
 * @param {Blob} blob Blob or File
 * @returns {Promise<string>}
 */
export async function blobToString(blob) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = function (e) {
            const base64String = e.target.result.toString();
            resolve(base64String);
        };

        reader.onerror = function (e) {
            reject(e);
        };

        reader.readAsDataURL(blob);
    });
}
